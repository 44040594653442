import React, { useContext } from "react"
import Layout from "../../components/Layout"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BenefitsOfComputingLeasing from "../../components/education/BenefitsOfComputerLeasing"
import { Banner } from "../../components/Banner"
import Img from "gatsby-image"
import EducationOffers from "../../components/education/EducationOffers"
import { myContext } from "../../ThemeContext"
import SEO from "../../components/seo"
import SectionTitle from "../../components/SectionTitle"

export default function ClimateControl() {
  const data = useStaticQuery(graphql`
    query educationClimateControl {
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_277502354.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature: file(
        relativePath: { eq: "education/AdobeStock_330251233.jpeg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Climate Control Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="Maintain a happy and focused classroom"
              title="Climate Control"
            />
          </Col>
        </Row>
        <FeatureTextWithImage image={data.feature} />
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="We can provide education finance on any equipment within the school such as kitchens, science equipment, design technology equipment, astro-turf pitches and even double-glazing!"
          link={{
            text: "See our Bespoke Solutions",
            to: "/education/Bespoke/",
          }}
        />
      </Container>
      <BenefitsOfComputingLeasing />
    </Layout>
  )
}

function FeatureTextWithImage({ image }) {
  const theme = useContext(myContext)
  return (
    <FeatureSection>
      <Row>
        <Col lg="6">
          <Text theme={theme}>
            <h4>
              Climate control for your school is comfortably within your grasp
              and your budget too! Installing air conditioning isn't that
              expensive and won't significantly impact on your budget, all
              whilst keeping a happy and focused learning environment.
            </h4>
            <h4>
              We can provide you with a range of flexible payment options, and
              in turn you can provide your school with the cool environment the
              staff and pupils deserve!
            </h4>
          </Text>
        </Col>
        <Col lg={{ span: "6", order: "last" }} xs={{ order: "first" }}>
          <div className="cl-image-wrapper cl-bg">
            <div className="cl-image-wrapper-2">
              <Img
                fluid={image.childImageSharp.fluid}
                className="cl-bg-layer-inner"
                alt="Air conditioner in office setting"
              />
            </div>
          </div>
          <Img
            fluid={image.childImageSharp.fluid}
            className="cl-bg-layer-inner cl-img-fluid mobile-image"
            alt="Air conditioner in office setting"
          />
        </Col>
      </Row>
    </FeatureSection>
  )
}

const FeatureSection = styled.div`
  z-index: -1;
  margin-bottom: 10rem;
  .cl-image-wrapper {
    margin-left: -500px;
    margin-right: -350px;
    overflow: hidden;
    height: 100%;
    @media (max-width: 991px) {
      height: auto;
    }
    @media (max-width: 1199px) {
      margin: 0 !important;
    }
  }
  .cl-bg {
    position: relative;
  }
  .cl-bg-layer-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .cl-bg-layer-inner {
    background-color: inherit;
    opacity: 0.9;
  }
  .cl-image-wrapper-2 {
    z-index: -1;
    opacity: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .cl-img-fluid {
    max-width: 100%;
    height: auto;
  }
  .mobile-image {
    display: none;
  }
  @media (max-width: 991px) {
    .mobile-image {
      display: block;
    }
  }
`
const Text = styled.div`
  z-index: 9;
  background-color: ${props => props.theme.grey};
  color: #fff;
  padding: 4rem;
  margin-top: 80px;
  margin-bottom: -80px;
  padding-left: 415px;
  margin-left: -400px;
  @media (max-width: 991px) {
    padding: 40px 15px;
  }
  @media only screen and (max-width: 1199px) {
    padding: 50px 30px;
    margin: 0;
  }
`
